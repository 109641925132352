<!--会员管理-->
<!--<div class='Member'></div>-->
<!--   todo
1.机构组名称不能查询
2.机构组名称和机构名称的动态选择不能实现-->
<!--todo 导出不能实现-->

<template>
    <div class="Member" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form @submit.native.prevent :inline="true" v-model="form" size="small" label-width="85px">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="会员">
                            <el-input placeholder="会员名称/手机号" v-model="form.search" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="推荐人">
                            <el-select v-model="form.developMember" filterable>
                                <el-option value="" label="请选择" />
                                <el-option
                                    :value="g.code"
                                    :key="g.code"
                                    :label="g.realName"
                                    v-for="g in meta.developMembers"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="所属机构">
                            <el-select v-model="form.belongDept" filterable :clearable="true">
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="dept in meta.deptCodes"
                                    :label="dept.name"
                                    :key="dept.code"
                                    :value="dept.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row style="margin-top: 16px">
                    <el-col :span="8">
                        <el-form-item label="注册时间">
                            <el-date-picker
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                placeholder="选择日期"
                                size="small"
                                v-model="dateRange"
                                @change="dateRangeChange"
                                value-format="yyyy-MM-dd"
                                :editable="false"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="14">
                        <el-form-item label="会员地址">
                            <el-select v-model="form.provinceCode" style="width: 22%">
                                <el-option value="" label="请选择" />
                                <el-option :value="g.code" :key="g.code" :label="g.name" v-for="g in meta.provinces" />
                            </el-select>
                            <el-select v-model="form.cityCode" style="width: 22%; margin-left: 10px">
                                <el-option value="" label="请选择" />
                                <el-option :value="g.code" :key="g.code" :label="g.name" v-for="g in meta.cityList" />
                            </el-select>
                            <el-select v-model="form.districtCode" style="width: 22%; margin-left: 10px">
                                <el-option value="" label="请选择" />
                                <el-option
                                    :value="g.code"
                                    :key="g.code"
                                    :label="g.name"
                                    v-for="g in meta.districtList"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button type="primary" @click="handleQuery" size="small" v-if="hasPrivilege('menu.member.member.open')"
                >查询</el-button
            >
            <el-button
                type="primary"
                @click="handleCreate"
                size="small"
                v-if="hasPrivilege('menu.member.member.create')"
                >新建</el-button
            >
            <el-button
                type="primary"
                @click="handleExport"
                size="small"
                v-if="hasPrivilege('menu.member.member.export')"
                >导出</el-button
            >
            <el-button
                type="primary"
                @click="handleBatch"
                size="small"
                v-if="hasPrivilege('biz.member.update.batch.developMember')"
                >批量修改推荐人</el-button
            >
            <el-button
                type="primary"
                @click="handelChangeMerber"
                size="small"
                v-if="hasPrivilege('biz.member.update.batch.level')"
                >批量设置会员等级</el-button
            >
            <el-button
                type="primary"
                @click="handelChangeMemberBelongDept"
                v-if="hasPrivilege('biz.member.update.batch.belongDept')"
                size="small"
                >批量修改所属机构</el-button
            >
            <span style="float: right">
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                ref="table"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="440"
                @selection-change="handleSelectionChange"
            >
                <el-table-column label="全选" type="selection" width="60" align="center" fixed="left" />
                <el-table-column label="序号" width="60" type="index" fixed="left" />

                <el-table-column label="会员名称" min-width="120" prop="name" />
                <el-table-column label="手机号" width="100" prop="mobile" />
                <el-table-column label="性别" width="80" prop="sex">
                    <template slot-scope="scope">
                        <span>{{ scope.row.sex | sex }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="生日" min-width="100" prop="birthday" />
                <el-table-column label="会员等级" width="150" prop="levelStrList">
                    <template slot-scope="scope">
                        <div v-for="levelStr in scope.row.levelStrList" :key="levelStr">
                            <el-tooltip effect="dark" :content="levelStr" placement="left">
                                <p class="text-ellipsis">{{ levelStr }}</p>
                            </el-tooltip>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="推荐人" width="100" prop="developMember" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        <span>{{ developMemberName(scope.row) }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="所属机构" min-width="80" prop="belongDept" :show-overflow-tooltip="true" />
                <el-table-column label="注册渠道" width="100" prop="regChannel" :show-overflow-tooltip="true">
                    <template slot-scope="scope">
                        <span>{{ scope.row.regChannel | regChannel }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="注册时间" min-width="140" prop="createTime" />

                <el-table-column label="操作" width="450" header-align="center">
                    <template slot-scope="scope">
                        <el-button
                            size="small"
                            @click="rowDetail(scope.row)"
                            v-if="hasPrivilege('menu.member.member.open')"
                            >查看</el-button
                        >
                        <el-button
                            size="small"
                            type="warning"
                            @click="rowEdit(scope.row)"
                            v-if="hasPrivilege('menu.member.member.edit')"
                            >编辑</el-button
                        >
                        <el-button
                            size="small"
                            type="danger"
                            @click="handleDelete(scope.row)"
                            v-if="hasPrivilege('menu.member.member.delete')"
                            :disabled="!scope.row.deleteFlag"
                            >删除</el-button
                        >
                        <el-button
                            size="small"
                            type="success"
                            @click="handleRecharge(scope.row)"
                            v-if="hasPrivilege('menu.member.member.rechargeRefund')"
                            >充值</el-button
                        >
                        <el-button
                            size="small"
                            type="success"
                            @click="handleRefund(scope.row)"
                            v-if="hasPrivilege('menu.member.member.rechargeRefund')"
                            >退款</el-button
                        >
                        <el-button
                            size="small"
                            type="warning"
                            @click="handleChangeLevel(scope.row)"
                            v-if="hasPrivilege('menu.member.member.edit')"
                            >变更等级</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
        <el-dialog top="200px" title="批量修改推荐人" :visible.sync="batch" width="40%">
            <batch ref="batch" :data="selectData" @close_popup="close_dialog" />
        </el-dialog>
        <el-dialog top="200px" title="批量设置会员等级" :visible.sync="batchMember" width="40%">
            <batchMember ref="batchMember" :data="selectData" :searchData="searchForm" @close_popup="close_dialog" />
        </el-dialog>
        <el-dialog top="200px" title="批量修改会员所属机构" :visible.sync="batchMemberBelongDept" width="40%">
            <batchMemberBelongDept ref="batchMemberBelongDept" :data="selectData" @close_popup="close_dialog" />
        </el-dialog>
    </div>
</template>

<script>
import Util from 'js/Util';
import UrlUtils from 'js/UrlUtils';
import batch from './member/MemberBanchUpdate.vue';
import batchMember from './member/MemberChangeUpdate.vue';
import batchMemberBelongDept from './member/MemberBanchUpdateBelongDept.vue';
import Provinces from 'js/Provinces';
import { get } from 'request/http';

export default {
    components: {
        batch,
        batchMember,
        batchMemberBelongDept,
    },
    name: 'Member',
    data() {
        return {
            batchMember: false,
            batchMemberBelongDept: false,
            batch: false,
            form: {
                search: '',
                belongDept: '',
                provinceCode: '',
                cityCode: '',
                districtCode: '',
                address: '',
                developMember: '',
                sex: '',
                startTime: '',
                endTime: '',
                page: 1,
                limit: Util.Limit,
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            selectData: [],
            meta: {
                groups: [],
                deptCodes: [],
                provinces: Provinces,
                cityList: [],
                districtList: [],
                developMembers: [],
            },
            searchForm: [],
            current: {
                typeIdx: 3,
            },
            loadingDeptGroupFlag: true,
            tableData: [],
            url: {
                page: '/member/page',
                delete: '/member/delete',
                queryDeptByGroup: '/system/dept/deptTree',
                queryGroups: '/system/deptGroup/list',
                queryArea: '/system/area/list',
            },
            dateRange: [],
        };
    },
    filters: {
        sex(sex) {
            if (typeof sex == 'undefined') {
                return '';
            }
            if (sex == '0') {
                return '男';
            }
            if (sex == '1') {
                return '女';
            }
        },
        regChannel(regChannel) {
            if (typeof regChannel == 'undefined') {
                return '';
            }
            if (regChannel == '8') {
                return '后台新建';
            }
            if (regChannel == '10') {
                return '会员初始化';
            }
        },
    },
    watch: {
        'form.provinceCode': async function (newProvinceCode) {
            this.form.cityCode = '';
            this.form.districtCode = '';
            this.meta.districtList = [];
            let cityList = [];
            if (newProvinceCode) {
                //根据省份查询市列表
                cityList = await get(this.url.queryArea, { parentCode: newProvinceCode });
            }
            this.meta.cityList = cityList || [];
        },
        'form.cityCode': async function (newCityCode) {
            this.form.districtCode = '';
            let districtList = [];
            if (newCityCode) {
                //根据市查询地区列表
                districtList = await get(this.url.queryArea, { parentCode: newCityCode });
            }
            this.meta.districtList = districtList || [];
        },
    },
    mounted() {
        //查询可选的所属机构并且选中第一个
        this.handleBelongDept();
        //查询所有管理或所属机构的员工列表
        this.$efApi.staffApi.allRelatedStaff().then((rst = []) => {
            this.meta.developMembers = rst.filter(
                //排除掉超级管理员(code:TRSSTF00000000000001)，过滤出可以展示的推荐人列表
                (e) => e.code !== 'TRSSTF00000000000001' && this.canShowThisDevelopMember(e.code)
            );
        });
    },
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    methods: {
        close_dialog() {
            this.batch = false;
            this.batchMember = false;
            this.batchMemberBelongDept = false;
            this.handleQuery();
        },
        handleSelectionChange(val) {
            this.selectData = val;
        },
        handleBatch() {
            if (this.selectData.length !== 0) {
                const codeArray = [];
                this.selectData.forEach((item, index) => {
                    codeArray.push(item.code);
                });
                const _params = { codes: codeArray };
                get('/member/validateBatchUpdate', _params).then((rst) => {
                    if (rst == true) {
                        this.batch = true;
                    } else {
                        this.$message({
                            type: 'info',
                            message: '只能变更会员所属机构为您所在机构的会员推荐人!',
                        });
                    }
                });
                // this.batch = true;
            } else {
                this.$message({
                    type: 'info',
                    message: '请勾选需要批量修改的会员!',
                });
            }
        },
        handelChangeMerber() {
            if (this.selectData.length !== 0) {
                this.searchForm = this.form;
                this.batchMember = true;
                // this.batch = true;
            } else {
                this.$message({
                    type: 'info',
                    message: '请勾选需要批量修改的会员!',
                });
            }
        },
        handelChangeMemberBelongDept() {
            if (this.selectData.length !== 0) {
                this.searchForm = this.form;
                this.batchMemberBelongDept = true;
            } else {
                this.$message({
                    type: 'info',
                    message: '请勾选需要批量修改的会员!',
                });
            }
        },
        handlePrint() {
            this.printHTML('printMe', this.$route.meta.title);
        },
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params, (data) => {
                data.data.map((d) => (d.birthday = d.birthday ? this.formatDate(new Date(d.birthday)) : null));
                this.tableData = data.data;
                this.page.total = data.count;
            });
        },
        handleCreate() {
            Util.nameJump(this, 'menu.member.member.create', ['会员管理', '会员管理', '创建会员']);
        },
        rowDetail(row) {
            Util.nameJump(this, 'menu.member.member.detail', ['会员管理', '会员管理', '会员管理详情'], {
                form: row,
                code: row.code,
            });
        },
        rowEdit(row) {
            Util.nameJump(this, 'menu.member.member.edit', ['会员管理', '会员管理', '编辑会员管理'], {
                code: row.code,
            });
        },
        handleRecharge(row) {
            Util.nameJump(this, 'menu.member.member.recharge', ['会员管理', '会员管理', '会员充值'], {
                form: row,
                code: row.code,
            });
        },
        handleRefund(row) {
            Util.nameJump(this, 'menu.member.member.refund', ['会员管理', '会员管理', '会员账号退款'], {
                form: row,
                code: row.code,
            });
        },
        handleDelete(row) {
            UrlUtils.DeleteRemote(this, this.url.delete, row.code);
        },
        handleChangeLevel(row) {
            Util.nameJump(this, 'menu.member.member.changeLevel', ['会员管理', '会员管理', '变更等级'], {
                form: row,
                code: row.code,
            });
        },
        handleExport() {
            const codes = (this.$refs.table.selection || []).map((e) => e.code);

            UrlUtils.Export(this, '会员管理', '/member/export', this.form, codes);
        },
        developMemberName(rowData = {}) {
            return this.canShowThisDevelopMember(rowData.developMember) ? rowData.developMemberName : '';
        },
        canShowThisDevelopMember(developMember = '') {
            const staffType = this.$store.state.session.staffType;
            const staffCode = this.$store.state.session.code;
            const superStaffFlag = this.$store.state.session.superStaffFlag;
            //当前登录用户是管理人员或者推荐人是登录用户则显示推荐人信息 或者当前用户是超级业务员
            return staffType === 0 || staffCode === developMember || superStaffFlag;
        },
        dateRangeChange() {
            if (!this.dateRange) {
                this.dateRange = [];
                this.form.startTime = '';
                this.form.endTime = '';
            } else {
                this.form.startTime = this.dateRange[0];
                this.form.endTime = this.dateRange[1];
            }
        },
        handleBelongDept() {
            const _this = this;
            _this.form.belongDept = '';
            UrlUtils.QueryRemote(this, this.url.queryDeptByGroup + '?deptGroupCode=' + '', (rst) => {
                _this.meta.deptCodes = rst;
                _this.form.belongDept = _this.meta.deptCodes[0].code;
                this.loadingDeptGroupFlag = false;
                _this.handleQuery();
            });
        },
    },
};
</script>

<style scoped>
.Member .el-form-item {
    margin-bottom: 0;
}
.text-ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
</style>
