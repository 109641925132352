<template>
    <div class="GoodsEdit" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form ref="refForm" label-width="120px" :model="form" :rules="rules">
                <p style="color: orangered">未勾选任何会员时，默认为当前查询出的所有会员设置级别</p>
                <el-form-item label="机构组名称：" prop="regDept">
                    <el-select v-model="form.regDept" filterable @change="queryRelatedStaff">
                        <el-option value="" label="请选择" />
                        <el-option
                            v-for="dept in meta.deptCodes"
                            :label="dept.name"
                            :key="dept.code"
                            :value="dept.code"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item label="变更等级：" prop="developMember">
                    <el-select v-model="form.developMember" ref="selectStaff" filterable>
                        <el-option value="" label="请选择" />
                        <el-option
                            v-for="staff in meta.memberLevels"
                            :value="staff.level"
                            :key="staff.level"
                            :label="staff.name + '(' + staff.discount + '折)'"
                        />
                    </el-select>
                </el-form-item>
                <div style="text-align: right">
                    <el-button type="primary" @click="handleSave('refForm')">保存</el-button>
                </div>
            </el-form>
        </el-card>
    </div>
</template>
<script>
import UrlUtils from '../../../../js/UrlUtils';
import Util from 'js/Util';
import { Message } from 'element-ui';
// import { patchByFormData, patchByFormDataAfterConfirmed } from 'request/http';
import Utils from 'js/Util';

export default {
    name: 'MemberChangeUpdate',
    props: ['data', 'searchData'],
    data() {
        return {
            form: {
                regDept: '',
                developMember: '',
                codes: [],
            },
            memberLevel: {
                ewLevel: '',
                nowLevel: '',
            },
            meta: {
                deptCodes: [],
                staffs: [],
                selectData: [],
                form: [],
                memberLevels: [],
            },
            url: {
                queryRelatedStaff: '/system/staff/relatedStaff',
                queryDeptAll: '/system/deptGroup/staffManagedAllDeptGroups',
                save: '/member/batchChangeMemberLevel',
                queryMemberLevel: '/member/level/queryMemberLevel',
            },
            rules: {
                developMember: [{ required: true, message: '请选择会员等级', trigger: 'change' }],
            },
        };
    },
    mounted() {
        this.handleChangeGroup();
    },
    methods: {
        handleChangeGroup() {
            const _this = this;
            _this.form.regDept = '';
            UrlUtils.QueryRemote(this, this.url.queryDeptAll, (rst) => {
                _this.meta.deptCodes = rst;
            });
        },
        handleMemberLever(value) {
            const _this = this;
            const _params = { params: { deptGroup: value, memberCode: '' } };
            Utils.queryTable(_this, _this.url.queryMemberLevel, _params, (data) => {
                _this.meta.memberLevels = data.data.memberLevels;
                _this.memberLevel.newLevel = data.data.nowLevel;
                _this.memberLevel.nowLevel = data.data.nowLevel || '未设置';
            });
        },
        queryRelatedStaff(deptCode) {
            const params = { params: { deptCode: deptCode } };
            Util.queryTable(this, this.url.queryRelatedStaff, params, (data) => {
                if (this.$store.state.session.name !== 'admin') {
                    this.meta.staffs = data.data.filter((value) => {
                        return value.username !== 'admin';
                    });
                } else {
                    this.meta.staffs = data.data;
                }
            });
            this.handleMemberLever(deptCode);
        },

        handleSave(name) {
            this.$refs[name].validate((valid) => {
                if (valid) {
                    this.meta.selectData = this.data;
                    this.form.codes = [];
                    this.meta.selectData.forEach((item, index) => {
                        this.form.codes.push(item.code);
                    });
                    UrlUtils.PostPriceBatchRemote(
                        this,
                        this.url.save,
                        {
                            deptGroupCode: this.form.regDept,
                            level: this.form.developMember,
                            memberCodes: this.form.codes,
                        },
                        null,
                        () => {
                            this.form.developMember = '';
                            this.form.regDept = '';
                            Message.success({ message: '操作成功' });
                            this.$emit('close_popup');
                        }
                    );
                }
            });
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}
</style>
