<template>
    <div class="GoodsEdit" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form ref="refForm" label-width="80px" :model="form" :rules="rules">
                <el-form-item label="机构：" prop="belongDept">
                    <el-select v-model="form.belongDept" filterable ref="belongDept">
                        <el-option value="" label="请选择" />
                        <el-option
                            v-for="dept in meta.deptCodes"
                            :label="dept.name"
                            :key="dept.code"
                            :value="dept.code"
                        />
                    </el-select>
                </el-form-item>
                <div style="text-align: right">
                    <el-button type="primary" @click="handleSave('refForm')">保存</el-button>
                </div>
            </el-form>
        </el-card>
    </div>
</template>
<script>
import UrlUtils from '../../../../js/UrlUtils';
import { Message } from 'element-ui';

export default {
    name: 'MemberBatchUpdate',
    props: ['data'],
    data() {
        return {
            form: {
                belongDept: '',
                codes: [],
            },
            meta: {
                deptCodes: [],
                selectData: [],
            },
            url: {
                queryDeptAll: '/system/dept/staffManagedAllDepts',
                save: '/member/batchUpdateBelongDept',
            },
            rules: {
                belongDept: [{ required: true, message: '请选择所属机构', trigger: 'change' }],
            },
        };
    },
    mounted() {
        this.queryDeptAll();
    },
    methods: {
        queryDeptAll() {
            const _this = this;
            _this.form.regDept = '';
            UrlUtils.QueryRemote(this, this.url.queryDeptAll, (rst) => {
                _this.meta.deptCodes = rst;
            });
        },

        handleSave(name) {
            this.$refs[name].validate((valid) => {
                if (valid) {
                    this.meta.selectData = this.data;
                    this.form.codes = [];
                    this.meta.selectData.forEach((item, index) => {
                        this.form.codes.push(item.code);
                    });
                    const message = this.form.belongDept ? '【' + this.$refs.belongDept.selected.label + '】' : '【】';
                    this.form.message = '确认新所属机构为' + message;
                    UrlUtils.PostRemote(this, this.url.save, this.form, null, () => {
                        this.form.belongDept = '';
                        Message.success({ message: '保存成功' });
                        this.$emit('close_popup');
                    });
                }
            });
        },
    },
};
</script>

<style scoped>
.el-input {
    width: auto;
}
</style>
